import React, { useState, useRef } from 'react';

function InputArea({ onSendMessage, isLoading, setShowMoodOptions, showMoodOptions }) {
  const [inputText, setInputText] = useState('');
  const inputTextRef = useRef(null);

  const handleSendClick = () => {
    onSendMessage(inputText);
    setInputText('');
    inputTextRef.current.focus();
    setShowMoodOptions(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleSendClick();
      event.preventDefault();
    }
  };

  return (
    <div>
      <div className="bg-white p-2 flex flex-col items-center gap-4">
        <div className='flex flex-row w-full gap-2 justify-between'>
          <button
            onClick={() => setShowMoodOptions(!showMoodOptions)}
            className="bg-[#FFFAF5] border border-[#213051] text-[#213051] rounded-full py-2 px-1 sm:px-3 ml-2 hover:bg-[#60C0F6] transition-colors"
            disabled={isLoading}
          >
            Drink mood check?
          </button>
          <input
            ref={inputTextRef}
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Type your message here..."
            aria-label="Type your message"
            className="flex-1 p-2 border border-[#213051] rounded-md transition-colors text-[#333]"
            disabled={isLoading}
          />
          <button
            onClick={handleSendClick}
            className={`border border-[#213051] bg-[#213051] text-white rounded-full py-2 ml-2 transition-colors px-2 flex my-auto`}
            aria-label="Send message"
          >
            <i className="fas fa-paper-plane" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default InputArea;
