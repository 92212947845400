import React from "react";

const Home = ({ setShowChatBot }) => {
    return (
        <div class="container">
            <div class="hero">
            <h1>Discover Your Perfect Cocktail 🍸</h1>
            <p>AI-powered recommendations tailored just for you.</p>
            <div class="input-group">
                <button class="join-btn" onClick={(e) => { e.preventDefault(); setShowChatBot(true); }}>Find Your Drink</button>
            </div>
            </div>

            <div class="features">
            <div class="feature">
                <h3>🍹 Personalized Picks</h3>
                <p>Tell us what you like, and we’ll suggest the perfect cocktail.</p>
            </div>
            <div class="feature">
                <h3>🔍 Endless Discoveries</h3>
                <p>Explore new cocktails, classics, and unique recipes.</p>
            </div>
            <div class="feature">
                <h3>✅ Easy to Follow</h3>
                <p>Step-by-step instructions to craft your perfect drink at home.</p>
            </div>
            </div>

            {/* <div class="cta">
            <h2>Join the SipSage Experience</h2>
            <p>Sign up now for exclusive early access.</p>
            <div class="input-group mt-4">
                <input className='email-input' type="email" id="email-cta" placeholder="Enter your email" />
                <button class="join-btn">Join Waitlist</button>
            </div>
            </div> */}
        </div>
    )
}

export default Home;
