import React, { useState } from 'react';
import './App.css';
import useViewportHeight from './useViewportHeight';
import CocktailbotApp from './CocktailbotApp';
import Home from './Home';

function App() {
  const viewportHeight = useViewportHeight();
  const [showChatBot, setShowChatBot] = useState(false);  

  document.documentElement.style.setProperty('--app-height', `${viewportHeight}px`);

  return (
    <div style={{ height: 'var(--app-height)' }}>
      { showChatBot ? <CocktailbotApp /> : <Home setShowChatBot={setShowChatBot} /> }
    </div>
  );
}

export default App;
