import { useEffect, useRef } from 'react';

const ChatArea = ({ messages, isLoading, triggerOrderResponse, updatePayment, price, link }) => {
  const chatContainerRef = useRef(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const updateOrderResponse = () => {
    triggerOrderResponse();
  }

  return (
    <div ref={chatContainerRef} className="relative flex-1 p-4 overflow-y-auto bg-[#60C0F6] text-white">
      {Boolean(messages.length) && messages.map((msg, index) => {
        const lastSystemMessageIndex = [...messages].reverse().findIndex(m => m.from !== 'user');
        const actualLastSystemMessageIndex = lastSystemMessageIndex !== -1 ? messages.length - 1 - lastSystemMessageIndex : -1;
        return (
          <div key={index} className={`flex items-end ${msg?.from === 'user' ? 'justify-end text-right' : 'justify-start text-left'}`}>
            <div className={`${msg?.from === 'user' ? 'bg-white border border-[#213051] text-[#213051]' : 'bg-[#213051] text-white'} rounded-lg px-4 py-2 max-w-xs m-2`}>
              {msg?.text}
              {msg?.from !== 'user' && msg?.text?.includes('Do you want to order the cocktail?') && index === actualLastSystemMessageIndex && (
                <div className="flex justify-end mt-2" onClick={() => updateOrderResponse()}>
                  {
                    (price && link) ? 
                      <a
                        className="bg-white text-[#213051] border border-[#213051] hover:bg-[#FEE9E1] rounded-lg px-2 py-1 mx-1"
                        href={link} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        aria-label="Order now, opens in a new tab"
                      >
                        <i class="fas fa-thumbs-up"></i> Yes, buy now!
                      </a> 
                    : <button className="bg-white text-[#213051] border border-[#213051] hover:bg-[#FEE9E1] rounded-lg px-2 py-1 mx-1"><i class="fas fa-thumbs-up"></i> Yes, order now!</button>
                  }
                </div>
              )}
              {msg?.from !== 'user' && msg?.text?.includes('Do you want to proceed to payment?') && index === actualLastSystemMessageIndex && (
                <div className="flex justify-end mt-2" onClick={() => updatePayment()}>
                  <button className="bg-white text-[#213051] border border-[#213051] hover:bg-[#FEE9E1] rounded-lg px-2 py-1 mx-1"><i class="fas fa-thumbs-up"></i> Yes, proceed to payment!</button>
                </div>
              )}
            </div>
          </div>
        );
      })}
      {isLoading && <div className="flex justify-start items-center py-2">
        <div className="bg-[#213051] rounded-lg px-4 py-2 max-w-xs m-2 flex items-center gap-1">
          <div className="w-2 h-2 bg-white rounded-full animate-dotFlashing"></div>
          <div className="w-2 h-2 bg-white rounded-full animate-dotFlashing" style={{ animationDelay: '0.2s' }}></div>
          <div className="w-2 h-2 bg-white rounded-full animate-dotFlashing" style={{ animationDelay: '0.4s' }}></div>
        </div>
      </div>}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default ChatArea;
