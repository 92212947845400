const MoodOptions = ({ selectedMoodOption, setShowMoodOptions }) => {
    const moods = {
      'Relaxing': 'option bg-teal text-white font-bold text-2xl py-16 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 text-center',
      'Celebratory': 'option bg-pink-600 hover:bg-pink-700 text-white font-bold text-2xl py-16 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 text-center',
      'Romantic': 'option bg-red-500 hover:bg-red-600 text-white font-bold text-2xl py-16 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 text-center',
      'Energetic': 'option bg-gradient-to-r from-yellow-400 to-orange-500 hover:from-yellow-500 hover:to-orange-600 text-white font-bold text-2xl py-16 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 text-center',
      'Cozy': 'option bg-amber-400 hover:bg-amber-500 text-white font-bold text-2xl py-16 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 text-center',
      'Adventurous': 'option bg-orange-700 hover:bg-orange-800 text-white font-bold text-2xl py-16 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 text-center'
    }
    return (
      <div className="relative flex-1 p-4 overflow-y-auto bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {Object.keys(moods).map((key, index) => (
            <div
              key={index}
              className={moods[key]}
              onClick={() => { selectedMoodOption(key); setShowMoodOptions(false); }}
            >
              {key}
            </div>
          ))}
        </div>
      </div>
    )
}

export default MoodOptions;
